@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");



.news-detail-header-sectionSing{
  background-image: url('../images/img/bedroomE.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  padding-top: 250px;
  padding-bottom: 150px;
  background-attachment: fixed;
}
.login-container {
  position: relative;
  width: 100%;
  background-color: #fff;
  min-height: 100vh;
  overflow: hidden;
}

.login-forms-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.login-signin-signup {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 75%;
  width: 50%;
  transition: 1s 0.7s ease-in-out;
  display: grid;
  grid-template-columns: 1fr;
  z-index: 5;
}

form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0rem 5rem;
  transition: all 0.2s 0.7s;
  overflow: hidden;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

form.login-sign-up-form {
  opacity: 0;
  z-index: 1;
}

form.login-sign-in-form {
  z-index: 2;
}

.login-title {
  font-size: 2.2rem;
  color: #444;
  margin-bottom: 10px;
}

.login-input-field {
  max-width: 380px;
  width: 100%;
  background-color: #f0f0f0;
 border: 1px solid #323220;
  margin: 10px 0;
  height: 55px;
  border-radius: 55px;
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 0 0.4rem;
  position: relative;
}

.login-input-field i {
  text-align: center;
  line-height: 55px;
  color: #acacac;
  transition: 0.5s;
  font-size: 1.1rem;
}

.login-input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
}

.login-input-field input::placeholder {
  color: #aaa;
  font-weight: 500;
}

.login-social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.login-social-media {
  display: flex;
  justify-content: center;
}

.login-social-icon {
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.45rem;
  color: #333;
  border-radius: 50%;
  border: 1px solid #333;
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.3s;
}

.login-social-icon:hover {
  /* color: #4481eb;
  border-color: #4481eb; */
}

.registerButton,.signupButton {
  width: 150px;
  background-color: #323220;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.registerButton:hover {
  background-color: #ffffff;
  color: #323220;
  border: 2px solid #c19038;
}
.signupButton:hover {
  background-color: #ffffff;
  color: #323220;
  border: 2px solid #c19038;
}

.login-panels-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.login-container:before {
  content: "";
  position: absolute;
  height: 2000px;
  width: 2000px;
  top: -10%;
  right: 48%;
  transform: translateY(-50%);
  background-image: linear-gradient(-45deg, #c19038 0%, #e9eaeb 100%);
  transition: 1.8s ease-in-out;
  border-radius: 50%;
  z-index: 6;
}

.login-image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.login-panel {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  text-align: center;
  z-index: 6;
}

.login-left-panel {
  pointer-events: all;
  padding: 3rem 17% 2rem 12%;
}

.login-right-panel {
  pointer-events: none;
  padding: 3rem 12% 2rem 17%;
}

.login-panel .login-content {
  color: #fff;
  transition: transform 0.9s ease-in-out;
  transition-delay: 0.6s;
}

.login-panel h2 {
  font-weight: 600;
  line-height: 1;
  font-size: 2rem;
  color: #FCFFE7;
}

.login-panel p {
  font-size: 1.1rem;
  padding: 0.7rem 0;
  color: #FCFFE7;

}

.login-btn.login-transparent {
  margin: 0;
  background: none;
  border: 2px solid #fff;
  width: 130px;
  height: 41px;
  font-weight: 600;
  font-size: 0.8rem;
}

.login-right-panel .login-image,
.login-right-panel .login-content {
  transform: translateX(800px);
}

/* ANIMATION */

.login-container.sign-up-mode:before {
  transform: translate(100%, -50%);
  right: 52%;
}

.login-container.sign-up-mode .login-left-panel .login-image,
.login-container.sign-up-mode .login-left-panel .login-content {
  transform: translateX(-800px);
}

.login-container.sign-up-mode .login-signin-signup {
  left: 25%;
}

.login-container.sign-up-mode form.login-sign-up-form {
  opacity: 1;
  z-index: 2;
}

.login-container.sign-up-mode form.login-sign-in-form {
  opacity: 0;
  z-index: 1;
}

.login-container.sign-up-mode .login-right-panel .login-image,
.login-container.sign-up-mode .login-right-panel .login-content {
  transform: translateX(0%);
}

.login-container.sign-up-mode .login-left-panel {
  pointer-events: none;
}

.login-container.sign-up-mode .login-right-panel {
  pointer-events: all;
}

@media (max-width: 870px) {
  .login-container {
    min-height: 800px;
    height: 100vh;
  }
  .login-signin-signup {
    width: 100%;
    top: 95%;
    transform: translate(-50%, -100%);
    transition: 1s 0.8s ease-in-out;
  }

  .login-signin-signup,
  .login-container.sign-up-mode .login-signin-signup {
    left: 50%;
  }

  .login-panels-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 2fr 1fr;
  }

  .login-panel {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 2.5rem 8%;
    grid-column: 1 / 2;
  }

  .login-right-panel {
    grid-row: 3 / 4;
  }

  .login-left-panel {
    grid-row: 1 / 2;
  }

  .login-image {
    width: 200px;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.6s;
  }

  .login-panel .login-content {
    padding-right: 15%;
    transition: transform 0.9s ease-in-out;
    transition-delay: 0.8s;
  }

  .login-panel h3 {
    font-size: 1.2rem;
  }

  .login-panel p {
    font-size: 0.7rem;
    padding: 0.5rem 0;
  }

  .login-btn.login-transparent {
    width: 110px;
    height: 35px;
    font-size: 0.7rem;
  }

  .login-container:before {
    width: 1500px;
    height: 1500px;
    transform: translateX(-50%);
    left: 30%;
    bottom: 68%;
    right: initial;
    top: initial;
    transition: 2s ease-in-out;
  }

  .login-container.sign-up-mode:before {
    transform: translate(-50%, 100%);
    bottom: 32%;
    right: initial;
  }

  .login-container.sign-up-mode .login-left-panel .login-image,
  .login-container.sign-up-mode .login-left-panel .login-content {
    transform: translateY(-300px);
  }

  .login-container.sign-up-mode .login-right-panel .login-image,
  .login-container.sign-up-mode .login-right-panel .login-content {
    transform: translateY(0px);
  }

  .login-right-panel .login-image,
  .login-right-panel .login-content {
    transform: translateY(300px);
  }

  .login-container.sign-up-mode .login-signin-signup {
    top: 5%;
    transform: translate(-50%, 0);
  }
}

@media (max-width: 570px) {
  form {
    padding: 0 1.5rem;
  }

  .login-image {
    display: none;
  }
  .login-panel .login-content {
    padding: 0.5rem 1rem;
  }
  .login-container {
    padding: 1.5rem;
  }

  .login-container:before {
    bottom: 72%;
    left: 50%;
  }

  .login-container.sign-up-mode:before {
    bottom: 28%;
    left: 50%;
  }
}
