::selection {
  background-color: #c19038;
  color: #fff;
  
}

::-webkit-scrollbar {
  width: 12px;
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background: #c19038;
}

/* Scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 6px;
  border: 2px solid #323220;
}

/* Scrollbar thumb hover */
::-webkit-scrollbar-thumb:hover {
  background: #323220;
  cursor: pointer;
}




