/* Container styling */
.slider-container {
  width: 100%;
  padding: 30px 0;
}

/* Hide default slick arrows */
.slick-arrow {
  display: none !important;
}

/* Custom Arrow Styling for cardButtons */
.cardButtons .custom-arrow {
  z-index: 1;
  background-color: rgba(239, 239, 239, 0.993);
  border-radius: 50%;
  border: 2px solid #323220;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 0.2s;
  margin: 0 10px; /* Add margin for spacing between arrows */
}

.cardButtons .custom-arrow img {
  width: 20px;
}

.cardButtons .custom-arrow:hover {
  background: #323220;
  cursor: pointer;
}

/* cardButtons container styling */
.cardButtons {
  width: 100%;
  display: flex;
  justify-content: center; 
  margin-top: 20px; 
}

/* Card styling */
.cards {
  padding: 10px;
  box-shadow: none !important;
  border: none !important;
}

.horizontal-card {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 50vh;
}

.horizontal-card:hover {
  transform: scale(1.05);
}

.images {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.card-img-top {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-body {
  padding: 15px;
  background-color: #fff;
  flex-grow: 1;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #000; /* Ensure the title link is clearly visible */
  text-decoration: none; /* Remove underline from the link */
}

.card-text {
  font-size: 1rem;
  color: #555;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .horizontal-card {
    height: 350px;
  }
  .images {
    height: 150px;
  }
}

@media (max-width: 768px) {
  .horizontal-card {
    height: 300px;
  }
  .images {
    height: 130px;
  }
}

@media (max-width: 576px) {
  .slider-container {
    padding: 15px 0;
  }

  .horizontal-card {
    width: auto;
    height: 300px;
    margin: 0 40px;
  }

  .images {
    height: 120px;
  }
}
