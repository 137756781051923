/* whatsapp icons  */

.whatsapp_float .fa-whatsapp{
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 100px;
    font-size: 30px;
    right: 25px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 10%;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s ease;

}



.whatsapp_float .fa-whatsapp:hover {
    background: #fff;
    color: #25d366;
    border: 2px solid #25d366;
}


.home_icon {
    position: fixed;
    bottom: 40px;
    right: 25px;
    background-color: #c19038;
    color: #323220;
    border-radius: 10%;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 60px;
    cursor: pointer;
    z-index: 999; /* Place behind the WhatsApp icon */
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.home_icon .fa-chevron-up{
    font-size: 24px;
    color: white;
}

.home_icon:hover {
    background-color: #323220;
    border: 2px solid #fff;
    transition: background-color 0.3s ease;
}
/* Responsive adjustments */
@media (max-width: 768px) {
    .whatsapp_float .fa-whatsapp{
        width:  45px;
        height: 45px;
        bottom: 75px;
        right: 15px;
    }

    .whatsapp_float .fa-whatsapp {
        font-size: 30px;
    }

    .home_icon {
        bottom: 18px;
        right: 15px;
        width: 45px;
        height: 45px;
        line-height: 45px;
    }

    .home_icon .fa-chevron-up {
        font-size: 22px;
    }
}

@media (max-width: 480px) {
    .whatsapp_float .fa-whatsapp{
        width: 40px;
        height: 40px;
        bottom: 70px;
        right: 15px;
        z-index: 100;
    }

    .whatsapp_float .fa-whatsapp {
        font-size: 30px;
    }

    .home_icon {
        bottom: 15px;
        right: 15px;
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .home_icon .fa-chevron-up {
        font-size: 18px;
    }
}
